import React from 'react'
import './Footer.css'


const Footer = () => {
  return (
    <footer className='app__footer'>
        <p>Copyright © 2024 Bramhani Brass Products</p>
    </footer>
  )
}

export default Footer
